<template>
    <div class="p-grid crud-demo">
        <div class="p-col-12">
            <div class="card">
                <Toast/>
                <h5>搜索条件</h5>
                <Toolbar class="p-mb-4">
                    <template v-slot:left>
                        <div class="p-grid p-formgrid">

                            <div class="p-col-12 p-mb-2 p-lg-4 p-mb-lg-0">
						<span class="p-input-icon-right">
							<InputText type="text" v-model="listFrom.userName" placeholder="备注名称"/>
							<i class="pi pi-search"/>
						</span>
                            </div>
                        </div>
                        <div style="margin-left: 1vw">
                            <Dropdown v-model="dropdownValue" :options="dropdownValues" optionLabel="name"
                                      placeholder="全部"/>
                        </div>
                        <div style="margin-left: 1vw">
                            <Button label="搜索" icon="pi pi-search" class="p-button-success p-mr-2" @click="selList()"/>
                        </div>
                    </template>
                </Toolbar>

                <Toolbar class="p-mb-4">
                    <template v-slot:left>
                        <Button label="新增" icon="pi pi-plus" class="p-button-success p-mr-2" @click="openNew"/>
                    </template>
                </Toolbar>

                <DataTable :value="products" :lazy="true" ref="dataTable"
                           :paginator="true" :rows="10" :totalRecords="totalRecords" :loading="loading"
                           @page="onPage($event)" :filters="filters" @sort="onPage($event)"
                           paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
                           currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计 {totalRecords} 记录"
                           responsiveLayout="scroll">
                    <template #header>
                        <div class="table-header p-d-flex p-flex-column p-flex-md-row p-jc-md-between">
                            <h5 class="p-m-0">员工信息列表</h5>
                            <!--<span class="p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global']" placeholder="Search..." />
                            </span>-->
                        </div>
                    </template>

                    <Column field="code" header="用户名称">
                        <template #body="slotProps">
                            <span class="p-column-title">userName</span>
                            {{slotProps.data.userName}}
                        </template>
                    </Column>
                    <Column field="name" header="手机号码">
                        <template #body="slotProps">
                            <span class="p-column-title">phone</span>
                            {{slotProps.data.phone}}
                        </template>
                    </Column>
                    <Column field="name" header="公司名称">
                        <template #body="slotProps">
                            <span class="p-column-title">companyName</span>
                            {{slotProps.data.companyName}}
                        </template>
                    </Column>
                    <Column field="code" header="备注名称">
                        <template #body="slotProps">
                            <span class="p-column-title">remarkName</span>
                            {{slotProps.data.remarkName}}
                        </template>
                    </Column>
                    <Column field="inventoryStatus" header="身份">
                        <template #body="slotProps">
                            <span class="p-column-title">Status</span>
                            <span :class="'product-badge status-' + slotProps.data.type"
                                  v-if="slotProps.data.type === 1">用户管理员</span>
                            <span :class="'product-badge status-' + slotProps.data.type"
                                  v-if="slotProps.data.type === 2">项目经理</span>
                            <span :class="'product-badge status-' + slotProps.data.type"
                                  v-if="slotProps.data.type === 3">安装检修员</span>
                        </template>
                    </Column>

                    <Column field="createTime" header="加入时间" :sortable="true">
                        <template #body="slotProps">
                            <span class="p-column-title">createTime</span>
                            {{e_fmtDate(slotProps.data.createTime)}}
                        </template>
                    </Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-success p-mr-2"
                                    @click="showProduct(slotProps.data)"/>
                            <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning p-mr-2" v-if="slotProps.data.type !== 1"
                                    @click="editProduct(slotProps.data)"/>
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" v-if="slotProps.data.type !== 1"
                                    @click="confirmDeleteProduct(slotProps.data)"/>
                        </template>
                    </Column>
                </DataTable>

                <Dialog v-model:visible="productShow" :style="{width: '500px',height: ''}" header="详细信息" :modal="true"
                        class="p-fluid">
                    <div class="p-field">
                        <label for="userName">用户名称</label>
                        <InputText id="userName" v-model.trim="product.userName" required="true" autofocus disabled/>
                    </div>
                    <div class="p-field">
                        <label for="companyName">公司名称</label>
                        <InputText id="companyName" v-model.trim="product.companyName" required="true" autofocus
                                   disabled/>
                    </div>
                    <div class="p-field">
                        <label for="remarkName">备注名称</label>
                        <InputText id="remarkName" v-model.trim="product.remarkName" required="true" autofocus
                                   disabled/>
                    </div>
                    <div class="p-field">
                        <label for="phone">手机名称</label>
                        <InputText id="phone" v-model.trim="product.phone" required="true" autofocus disabled/>
                    </div>
                    <div class="p-field">
                        <label for="type">身份</label>
                        <InputText id="type" placeholder="用户管理员" v-if="product.type === 1" required="true" autofocus
                                   disabled/>
                        <InputText id="type" placeholder="项目经理" v-if="product.type === 2" required="true" autofocus
                                   disabled/>
                        <InputText id="type" placeholder="安装检修员" v-if="product.type === 3" required="true" autofocus
                                   disabled/>
                    </div>
                    <div class="p-field">
                        <label for="createTime">加入时间</label>
                        <InputText id="createTime" v-model.trim="product.createTime" required="true" autofocus
                                   disabled/>
                    </div>
                    <div class="p-field">
                        <label for="updateTime">修改时间</label>
                        <InputText id="updateTime" v-model.trim="product.updateTime" required="true" autofocus
                                   disabled/>
                    </div>
                    <template #footer>
                        <Button label="关闭" icon="pi pi-times" class="p-button-text" @click="hideShow"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="productDialog" :style="{width: '450px'}" header="修改员工信息" :modal="true"
                        class="p-fluid">
                    <div class="p-field">
                        <label for="remname">备注名称</label>
                        <InputText id="remname" v-model.trim="product.remarkName" required="true" autofocus/>
                    </div>

                    <div class="p-field">
                        <label for="inventoryStatus" class="p-mb-3">状态</label>
                        <Dropdown id="inventoryStatus" v-model="product.type" :options="statuses" optionLabel="label"
                                  :placeholder="product.type">
                            <template #value="slotProps">
                                <div v-if="slotProps.value && slotProps.value.value">
                                    <span>{{slotProps.value.label}}</span>
                                </div>
                            </template>
                        </Dropdown>
                    </div>
                    <template #footer>
                        <Button label="取消" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
                        <Button label="确定" icon="pi pi-check" class="p-button-text" @click="saveProduct"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="productAdd" :style="{width: '450px'}" header="新增员工信息" :modal="true"
                        class="p-fluid">
                    <div class="p-field">
                        <label for="nameadd">备注姓名</label>
                        <InputText id="nameadd" v-model="product.remarkName" aria-required="true" autofocus/>
                    </div>
                    <div class="p-field">
                        <label for="phoneadd">手机号码</label>
                        <InputNumber id="phoneadd" v-model="product.phone" mode="decimal" :useGrouping="false"/>

                    </div>
                    <div class="p-field">
                        <label for="inventoryStatus" class="p-mb-3">状态</label>
                        <Dropdown id="inventoryStatus" v-model="product.type" :options="statuses" optionLabel="label"
                                  placeholder="请选择员工身份">

                        </Dropdown>
                    </div>
                    <template #footer>
                        <Button label="取消" icon="pi pi-times" class="p-button-text" @click="hideAdd"/>
                        <Button label="提交" icon="pi pi-check" class="p-button-text" @click="addProduct"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="删除" :modal="true">
                    <div class="confirmation-content">
                        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
                        <span v-if="product">确定要删除员工<b>{{product.remarkName}}</b>吗?</span>
                    </div>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" class="p-button-text"
                                @click="deleteProductDialog = false"/>
                        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteProduct"/>
                    </template>
                </Dialog>

                <Dialog v-model:visible="deleteProductsDialog" :style="{width: '450px'}" header="Confirm" :modal="true">
                    <div class="confirmation-content">
                        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
                        <span v-if="product">Are you sure you want to delete the selected products?</span>
                    </div>
                    <template #footer>
                        <Button label="No" icon="pi pi-times" class="p-button-text"
                                @click="deleteProductsDialog = false"/>
                        <Button label="Yes" icon="pi pi-check" class="p-button-text" @click="deleteSelectedProducts"/>
                    </template>
                </Dialog>
            </div>
        </div>
    </div>

</template>

<script>
    import ProductService from '../service/ProductService';

    import axios from "axios";

    export default {
        data() {
            return {
                totalRecords: 0,
                loading: false,
                products: null,
                productShow: false,
                productDialog: false,
                productAdd: false,
                deleteProductDialog: false,
                deleteProductsDialog: false,
                product: {},
                companyId: 0,
                selectedProducts: null,
                filters: {},
                page: 0,
                submitted: false,
                listFrom: {
                    userName: "",
                },
                statuses: [
                    {label: '项目经理', value: '2'},
                    {label: '安装检修员', value: '3'}
                ],
                dropdownValue: null,
                dropdownValues: [
                    {name: '全部', code: '0'},
                    {name: '用户管理员', code: '1'},
                    {name: '项目经理', code: '2'},
                    {name: '安装检修员', code: '3'}

                ]
            }
        },
        productService: null,
        created() {
            this.productService = new ProductService();
        },
        mounted() {
            /*    axios.get('assets/layout/data/products.json').then(data => this.products = data.data.data);*/
            /*
                        axios.post("/houseKeeperCompany/user/getCompanyBackStageByPage").then(data => this.products = data.data.data);
            */
            this.feath();
        },
        methods: {
            feath() {
                this.loading = true;
                const page = 0;
                axios
                    .post(
                        "/houseKeeperCompany/user/getCompanyStaffByPage",
                        `page=${page}`
                    )
                    .then(({data}) => {
                        if (data.code === 0) {
                            this.companyId = data.data.content[0].companyId;
                            this.products = data.data.content;
                            this.totalRecords = data.data.totalElements
                            this.loading = false;
                        } else{
                            if(data.msg === '当前账号在其他地点登录，请重新登录' && data.code === -3){
                                this.$router.push('/login');
                            }
                            this.err(data.msg, "查询失败", 5000);
                        }
                    })
                    .catch((err) => {
                        console.error(err);
                        this.err("系统错误，请稍候重试");
                    });
            },
            err(msg, title, life) {
                this.$toast.add({
                    severity: "error",
                    summary: title,
                    detail: msg,
                    life: life || 3000,
                });
            },
            onPage(event) {
                this.loading = true;
                const remarkName = this.listFrom.userName;
                let type = 0;
                if (this.dropdownValue !== null) {
                    type = this.dropdownValue.code
                }
                const page = event.page;
                this.page = event.page;
                let sort = '';
                if(event.sortField){
                    sort = event.sortField+','+ (event.sortOrder===1?'asc':'desc');
                }
                setTimeout(() => {
                    axios
                        .post(
                            "/houseKeeperCompany/user/getCompanyStaffByPage",
                            `page=${page}&size=${event.rows}&sort=${sort}&remarkName=${remarkName}&type=${type}`
                        )
                        .then(({data}) => {
                            if (data.code === 0) {
                                console.log(data);
                                this.products = data.data.content;
                            } else this.err(data.msg, "查询失败", 5000);
                        })
                        .catch((err) => {
                            console.error(err);
                            this.err("系统错误，请稍候重试");
                        });
                    this.loading = false;
                }, 500);
            },
            selList() {
                this.loading = true;
                const remarkName = this.listFrom.userName;
                let type = 0;
                if (this.dropdownValue !== null) {
                    type = this.dropdownValue.code
                }
                const page = this.page;
                this.$refs.dataTable.resetPage();
                setTimeout(() => {
                    axios
                        .post(
                            "/houseKeeperCompany/user/getCompanyStaffByPage",
                            `page=${page}&remarkName=${remarkName}&type=${type}`
                        )
                        .then(({data}) => {
                            if (data.code === 0) {
                                console.log(data);
                                this.products = data.data.content;
                                this.totalRecords = data.data.totalElements;
                            } else this.err(data.msg, "查询失败", 5000);
                        })
                        .catch((err) => {
                            console.error(err);
                            this.err("系统错误，请稍候重试");
                        });
                    this.loading = false;
                }, 500);
            },
            formatCurrency(value) {
                if (value)
                    return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'});
                return;
            },
            e_fmtDate(date, fmt) {
                if (typeof date === 'undefined') {
                    return
                }
                if (typeof date === 'string') {
                    if (date.endsWith('+0000')) {
                        date = new Date(Date.parse(new Date(date.substr(0, 19).replace(/T/g, ' ').replace(/-/g, '/'))) + 8 * 60 * 60 * 1000)
                    } else {
                        date = new Date(date.substr(0, 19).replace(/T/g, ' ').replace(/-/g, '/'))
                    }
                }
                var o = {
                    "M+": date.getMonth() + 1, //月份
                    "d+": date.getDate(), //日
                    "H+": date.getHours(), //小时
                    "m+": date.getMinutes(), //分
                    "s+": date.getSeconds(), //秒
                    "S": date.getMilliseconds() //毫秒
                };
                if (!fmt) fmt = 'yyyy-MM-dd HH:mm:ss'
                if (/(y+)/.test(fmt)) {
                    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + "").substr(4 - RegExp.$1.length));
                }
                for (var k in o)
                    if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[
                        k]).substr(("" + o[k]).length)));
                return fmt;
            },
            openNew() {
                this.product = {};
                this.submitted = false;
                this.productAdd = true;
            },
            hideShow() {
                this.productShow = false;
                this.submitted = false;
            },
            hideDialog() {
                this.productDialog = false;
                this.submitted = false;
            },
            hideAdd() {
                this.productAdd = false;
                this.submitted = false;
            },
            saveProduct() {
                if (!this.product.remarkName) return this.err("备注姓名不能为空");
                if (this.product.remarkName.length < 2 || !/^[a-zA-Z\u4e00-\u9fa5]+$/g.test(this.product.remarkName)) {
                    return this.err("请输入正确的备注姓名");
                }
                const toUserId = this.product.userId;
                const remark = this.product.remarkName;
                const newType = this.product.type.value
                this.submitted = true;
                axios
                    .post(
                        "/houseKeeperCompany/user/setCompanyUser",
                        `toUserId=${toUserId}&remark=${remark}&newType=${newType}`
                    )
                    .then(({data}) => {
                        if (data.code === 0) {
                            this.productDialog = false;
                            this.product = {};
                            this.$refs.dataTable.resetPage();
                            this.feath();
                            this.$toast.add({severity: 'success', summary: data.msg, detail: data.data, life: 3000});
                        } else this.err(data.msg, "查询失败", 5000);
                    })
                    .catch((err) => {
                        console.error(err);
                        this.err("系统错误，请稍候重试");
                    });

            },
            addProduct() {
                console.log(this.product);
                const remark = this.product.remarkName;
                const phone = this.product.phone;
                const newType = this.product.type;
                if (!phone) return this.err("请输入手机号");
                else if (!/^1[345789]\d{9}$/.test(phone))
                    return this.err("手机号格式不正确");
                if (!remark) return this.err("请输入备注姓名");
                if (remark.length < 2 || !/^[a-zA-Z\u4e00-\u9fa5]+$/g.test(remark)) {
                    return this.err("请输入正确的备注姓名");
                }
                if (!newType) return this.err("请选择身份");
                axios
                    .post(
                        "/houseKeeperCompany/user/addCompanyUser",
                        `phone=${phone}&newType=${newType.value}&remark=${remark}`
                    )
                    .then(({data}) => {
                        if (data.code === 0) {
                            this.productAdd = false;
                            this.product = {};
                            this.$refs.dataTable.resetPage();
                            this.feath();
                            this.$toast.add({severity: 'success', summary: data.msg, detail: data.data, life: 3000});
                        } else this.err(data.msg, "新增失败", 5000);
                    })
                    .catch((err) => {
                        console.error(err);
                        this.err("系统错误，请稍候重试");
                    });
            },
            showProduct(product) {

                this.product = {...product};
                this.product.createTime = this.e_fmtDate(this.product.createTime);
                this.product.updateTime = this.e_fmtDate(this.product.updateTime);
                this.productShow = true;
            },
            editProduct(product) {
                this.product = {...product};
                if (this.product.type === 1) {
                    this.product.type = {label: "用户管理员", value: "1"};
                } else if (this.product.type === 2) {
                    this.product.type = {label: "项目经理", value: "2"};
                } else {
                    this.product.type = {label: "安装检修员", value: "3"};
                }
                console.log(this.product.type.label)
                this.productDialog = true;
            },
            confirmDeleteProduct(product) {
                this.product = product;
                this.deleteProductDialog = true;
            },
            deleteProduct() {
                const toUserId = this.product.userId
                axios
                    .post(
                        "/houseKeeperCompany/user/delCompanyUser",
                        `toUserId=${toUserId}`
                    )
                    .then(({data}) => {
                        if (data.code === 0) {
                            this.product = {};
                            this.deleteProductDialog = false;
                            this.$refs.dataTable.resetPage();
                            this.feath();
                            this.$toast.add({
                                severity: 'success',
                                summary: '删除成功！',
                                detail: '删除员工成功！',
                                life: 3000
                            });
                        } else this.err(data.msg, "删除失败", 5000);
                    })
                    .catch((err) => {
                        console.error(err);
                        this.err("系统错误，请稍候重试");
                    });
            },
            findIndexById(id) {
                let index = -1;
                for (let i = 0; i < this.products.length; i++) {
                    if (this.products[i].id === id) {
                        index = i;
                        break;
                    }
                }
                return index;
            },
            createId() {
                let id = '';
                var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
                for (var i = 0; i < 5; i++) {
                    id += chars.charAt(Math.floor(Math.random() * chars.length));
                }
                return id;
            },
            exportCSV() {
                this.$refs.dt.exportCSV();
            },
            confirmDeleteSelected() {
                this.deleteProductsDialog = true;
            },
            deleteSelectedProducts() {
                this.products = this.products.filter(val => !this.selectedProducts.includes(val));
                this.deleteProductsDialog = false;
                this.selectedProducts = null;
                this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Products Deleted', life: 3000});
            }
        }
    }
</script>

<style scoped lang="scss">
    .table-header {
        display: flex;
        justify-content: space-between;
    }

    .product-image {
        width: 100px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }

    .p-dialog .product-image {
        width: 150px;
        margin: 0 auto 2rem auto;
        display: block;
    }

    .confirmation-content {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .product-badge {
        border-radius: 2px;
        padding: .25em .5rem;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: .3px;

        &.status-1 {
            background: #C8E6C9;
            color: #256029;
        }

        &.status-2 {
            background: #FFCDD2;
            color: #C63737;
        }

        &.status-3 {
            background: #FEEDAF;
            color: #8A5340;
        }
    }

    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: .25rem;
        }
    }
</style>